import React, { useState, useContext, useCallback, useMemo } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { DataContext } from '../contexts/DataContext';
import './CalendarView.css';
import { format } from 'date-fns'; 

const CalendarView = React.memo(() => {
  const { data } = useContext(DataContext);
  const [selectedDate, setSelectedDate] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [showPercentage, setShowPercentage] = useState(false); // State to toggle between percentage and dollar

  // Toggle handler to switch between percentage and dollar views
  const toggleView = () => {
    setShowPercentage(prevState => !prevState);
  };

  // Create a map of entries by date
  const entriesByDate = useMemo(() => {
    const entriesMap = {};
    data.entries.forEach(entry => {
      if (!entriesMap[entry.date]) {
        entriesMap[entry.date] = [];
      }
      entriesMap[entry.date].push(entry);
    });
    return entriesMap;
  }, [data.entries]);

  // Calculate the weighted average percentage gain/loss for all trades in a day
  const calculateWeightedPercentageForDay = (entriesForDay) => {
    let totalBoughtValue = 0;
    let totalSoldValue = 0;

    entriesForDay.forEach(entry => {
      const priceBoughtAt = entry.priceBoughtAt || 0;
      const priceSoldAt = entry.priceSoldAt || 0;
      const quantity = entry.quantity || 1;

      totalBoughtValue += priceBoughtAt * quantity;
      totalSoldValue += priceSoldAt * quantity;
    });

    if (totalBoughtValue === 0) {
      return 0; // Avoid division by zero
    }

    const totalPercentage = ((totalSoldValue - totalBoughtValue) / totalBoughtValue) * 100;
    return totalPercentage.toFixed(2);
  };

  const getTotalProfitLossForDay = useCallback((dateString) => {
    const entriesForDay = entriesByDate[dateString] || [];
    return entriesForDay.reduce((total, entry) => total + (entry.amount || 0), 0);
  }, [entriesByDate]);

  const renderTileContent = useCallback(({ date, view }) => {
    if (view === 'month') {
      const dateString = format(date, 'yyyy-MM-dd');
      const totalProfitLoss = getTotalProfitLossForDay(dateString);
      if (totalProfitLoss !== 0) {
        return (
          <div className={totalProfitLoss >= 0 ? 'positive' : 'negative'}>
            {showPercentage 
              ? `${calculateWeightedPercentageForDay(entriesByDate[dateString])}%` 
              : `${totalProfitLoss >= 0 
                  ? `+$${totalProfitLoss.toFixed(2)}` 
                  : `-$${Math.abs(totalProfitLoss).toFixed(2)}`}`}
          </div>
        );
      }
    }
    return null;
  }, [getTotalProfitLossForDay, showPercentage, entriesByDate]);
  
  const handleDayClick = useCallback((date) => {
    setSelectedDate(date);
    setModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setSelectedDate(null);
  }, []);

  // Sort entries by `timeSoldAt` in descending order
  const sortEntriesByTime = (entriesForDay) => {
    return [...entriesForDay].sort((a, b) => {
      const timeA = a.timeSoldAt || '';
      const timeB = b.timeSoldAt || '';
      return timeB.localeCompare(timeA); // Sort by time in descending order (latest first)
    });
  };

  const renderModalContent = useCallback(() => {
    if (selectedDate) {
      const dateString = format(selectedDate, 'yyyy-MM-dd');
      let entriesForDay = entriesByDate[dateString] || [];
      
      // Sort the entries by time (latest to earliest)
      entriesForDay = sortEntriesByTime(entriesForDay);

      const totalProfitLoss = getTotalProfitLossForDay(dateString);

      return (
        <div className="modal-content">
          <h2>Trades for {format(selectedDate, 'MMMM d, yyyy')}</h2>
          {entriesForDay.length > 0 ? (
            <>
              <div className="trades-list">
                {entriesForDay.map((entry, index) => (
                  <div key={index} className="trade-item">
                    <div className="trade-header">
                      <span className="stock-name">{entry.stockName}</span>
                      <span className={`trade-amount ${entry.amount >= 0 ? 'positive' : 'negative'}`}>
                        {showPercentage ? `${calculateWeightedPercentageForDay([entry])}%` : `$${entry.amount.toFixed(2)}`}
                      </span>
                    </div>
                    <div className="trade-details">
                      <p><strong>{entry.callPut}</strong></p>
                      <p><strong>Strike:</strong> {entry.optionsStrike}</p>
                      <p><strong>Qty:</strong> {entry.quantity}</p>
                      <p><strong>Bought:</strong> ${entry.priceBoughtAt.toFixed(2)}</p>
                      <p><strong>Sold:</strong> ${entry.priceSoldAt.toFixed(2)}</p>
                      <p><strong>Time Sold At:</strong> {entry.timeSoldAt || 'N/A'}</p> {/* Show time sold */}
                      <p><strong>Expiration:</strong> {entry.expiration ? format(new Date(entry.expiration), 'MMMM d, yyyy') : 'N/A'}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="total-profit-loss">
                <h3>Total {showPercentage ? 'Weighted Percentage' : 'Profit/Loss'}</h3>
                <span className={`amount ${totalProfitLoss >= 0 ? 'positive' : 'negative'}`}>
                  {showPercentage ? `${calculateWeightedPercentageForDay(entriesForDay)}%` : `$${totalProfitLoss.toFixed(2)}`}
                </span>
              </div>
            </>
          ) : (
            <p>No trades for this date</p>
          )}
          <button onClick={handleCloseModal} className="close-modal">Close</button>
        </div>
      );
    }
    return null;
  }, [selectedDate, entriesByDate, getTotalProfitLossForDay, showPercentage, handleCloseModal]);

  const formatShortWeekday = useCallback((locale, date) => {
    const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    return daysOfWeek[date.getDay()];
  }, []);

  return (
    <div className="calendar-container">
      <Calendar 
        value={selectedDate}
        onChange={setSelectedDate}
        onClickDay={handleDayClick} 
        tileContent={renderTileContent}
        formatShortWeekday={formatShortWeekday}
        formatMonthYear={(locale, date) => format(date, 'MMMM yyyy')}
        minDetail="month"
      />
      <div className="toggle-switch-container">
        <span className="switch-label">{showPercentage ? '%' : '$'}</span>
        <label className="switch">
          <input type="checkbox" checked={showPercentage} onChange={toggleView} />
          <span className="slider"></span>
        </label>
      </div>
      {modalOpen && selectedDate && (
        <div className="modal-overlay">
          {renderModalContent()}
        </div>
      )}
    </div>
  );
});

export default CalendarView;
