import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CalendarView from './components/CalendarView';
import AdminSection from './components/AdminSection';
import Login from './components/Login';
import { DataProvider, DataContext } from './contexts/DataContext';
import ProtectedRoute from './components/ProtectedRoute';
import Spinner from './components/Spinner';
import BalanceGraph from './components/BalanceGraph';
import WinPercentagePieChart from './components/WinPercentagePieChart';
import GoalProgressWheel from './components/GoalProgressWheel'; // Import the new component
import './styles.css';

const App = () => {
  return (
    <HelmetProvider>
      <DataProvider>
        <Router>
          <AppContent />
        </Router>
      </DataProvider>
    </HelmetProvider>
  );
};

const AppContent = () => {
  const { data, isLoading } = useContext(DataContext);

  if (isLoading) {
    return (
      <div className="full-page-spinner-overlay">
        <Spinner customClass="full-page-spinner" />
      </div>
    );
  }

  return (
    <div className="App">
      <Helmet>
        <title>Binh's Trading Journey</title>
      </Helmet>
      <header className="app-header">
        <div className="header-content">
          <h1>Binh's Trading Journey</h1>
          <nav>
            <NavLink to="/" end>Dashboard</NavLink>
            <NavLink to="/admin">Admin</NavLink>
          </nav>
        </div>
      </header>

      <main className="app-main">
        <Routes>
          <Route path="/" element={<Dashboard data={data} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={
            <ProtectedRoute>
              <AdminSection />
            </ProtectedRoute>
          } />
        </Routes>
      </main>
    </div>
  );
};

const Dashboard = ({ data }) => {
  const totalCalls = data.totalCalls || 0;
  const totalPuts = data.totalPuts || 0;
  const goal = 2000;

  // Set the text color based on streak type
  const streakColor = data.currentStreakType === 'win' ? '#2ecc71' : '#e74c3c';

  return (
    <div className="dashboard">
      <LeftContainer data={data} />
      <div className="right-container">
        <div className="custom-section">
          <div className="custom-box calls">
            <h2>Total Calls</h2>
            <span className="stat-value">{totalCalls} 📈 </span>
          </div>

          <div className="custom-box puts">
            <h2>Total Puts</h2>
            <span className="stat-value">{totalPuts} 📉 </span>
          </div>

          {/* Conditionally display the current streak with emojis and colored text */}
          <div className="custom-box">
            <h2>
              {data.currentStreakType === 'win' ? 'Win Streak' : 'Loss Streak'}
            </h2>
            <span
              className="stat-value"
              style={{ color: streakColor }} // Apply the conditional color
            >
              {data.currentStreakType === 'win'
                ? `${data.currentStreak} 🔥`
                : `${data.currentStreak} 😔`}
            </span>
          </div>

          <div className="custom-box">
            <GoalProgressWheel overallCash={parseFloat(data.overallCash)} goal={goal} />
          </div>

        </div>
        <BalanceGraph data={data.balanceData} />
        <div className="calendar-container">
          <CalendarView />
        </div>
      </div>
    </div>
  );
};




const LeftContainer = ({ data }) => {
  return (
    <div className="left-container">
      <div className="stat-card">
        <h2>Days Alive</h2>
        <span className="stat-value positive">{data.daysAlive}</span>
      </div>
      <div className="stat-card">
        <h2>Total Trades</h2>
        <span className="stat-value positive">{data.totalTrades}</span>
      </div>
      <div className="stat-card">
        <h2>Overall Cash</h2>
        <span className={`stat-value ${data.overallCash >= 0 ? 'positive' : 'negative'}`}>
          ${parseFloat(data.overallCash).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </span>
      </div>
      <div className="stat-card">
        <h2>Today's Profit/Loss</h2>
        <span className={`stat-value ${data.totalProfitLossToday >= 0 ? 'positive' : 'negative'}`}>
          ${parseFloat(data.totalProfitLossToday).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </span>
      </div>
      <div className="stat-card">
        <h2>Overall Profit/Loss</h2>
        <span className={`stat-value ${data.totalProfitLossOverall >= 0 ? 'positive' : 'negative'}`}>
          ${parseFloat(data.totalProfitLossOverall).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </span>
      </div>
      <div className="stat-card">
        <h2>Win Percentage</h2>
        <WinPercentagePieChart 
          winPercentage={data.winPercentage} 
          totalTrades={data.totalTrades} 
        />
      </div>
    </div>
  );
};


export default App;
