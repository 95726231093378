import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const WinPercentagePieChart = ({ winPercentage, totalTrades }) => {
  const lossPercentage = 100 - winPercentage;
  
  // Calculate the number of wins and losses
  const wins = Math.round((winPercentage / 100) * totalTrades);
  const losses = totalTrades - wins;

  const data = {
    labels: ['Wins', 'Losses'],
    datasets: [
      {
        label: 'Win Percentage',
        data: [wins, losses],
        backgroundColor: ['#36A2EB', '#FF6384'],
        hoverBackgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          // Customize the tooltip to show the number of wins or losses
          label: function (context) {
            const label = context.label || '';
            const value = context.raw || 0;
            if (label === 'Wins') {
              return `${label}: ${value} (${winPercentage}%)`; // Show total wins and percentage
            } else {
              return `${label}: ${value} (${lossPercentage}%)`; // Show total losses and percentage
            }
          },
        },
      },
    },
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Pie data={data} options={options} />
      <div>
        <h3
          style={{
            fontSize: '20px',
            color: '#2ecc71',
            marginTop: '20px',
            fontWeight: 'bold',
          }}
        >
          Win Percentage: {winPercentage}%
        </h3>
      </div>
    </div>
  );
};

export default WinPercentagePieChart;
