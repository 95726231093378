// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore'; // Import persistence function
import { getAuth } from 'firebase/auth'; // Import authentication service

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDt9NYlA2fueDBYbDZvGTUsg3MJjUXAHXc",
  authDomain: "options-calendar.firebaseapp.com",
  projectId: "options-calendar",
  storageBucket: "options-calendar.appspot.com",
  messagingSenderId: "290246643383",
  appId: "1:290246643383:web:c4e59eac1806b5e20d0732"
};

const app = initializeApp(firebaseConfig);

// Initialize Firestore and Firebase Auth
const db = getFirestore(app);
const auth = getAuth(app);

// Enable Firestore offline persistence
enableIndexedDbPersistence(db)
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled in one tab at a time
      console.error('Failed to enable persistence: Multiple tabs open');
    } else if (err.code === 'unimplemented') {
      // The current browser does not support all the features required to enable persistence
      console.error('Persistence is not available in this browser');
    }
  });

export { db, auth };
