import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const GoalProgressWheel = ({ overallCash, goal }) => {
  // Calculate progress percentage
  const percentage = Math.min((overallCash / goal) * 100, 100); // Max 100%

  return (
    <div className="goal-progress-container">

      <div className="goal-text">
        <p>Goal ${goal.toLocaleString()}</p>
      </div>
      
      {/* Wrap the progress bar in a container with controlled size */}
      <div className="progress-wheel-wrapper">
        <CircularProgressbar
          value={percentage}
          text={`${percentage.toFixed(2)}%`}
          styles={buildStyles({
            pathColor: overallCash >= goal ? 'green' : '#8FBC8F',
            textColor: overallCash >= goal ? '#7f8c8d' : '#7f8c8d',
            trailColor: '#d6d6d6',
          })}
        />
      </div>
      
      {/* Keep the goal text at the bottom */}
    </div>
  );
};

export default GoalProgressWheel;
