import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const BalanceGraph = ({ data }) => {
  if (!data || data.length === 0) return <div>No data available</div>;

  // Start with initial balance of 500
  const balanceData = [
    { date: data[0].date, balance: 500 },
    ...data.map(item => ({ ...item, balance: parseFloat(item.balance) }))
  ];

  // Format the balance to include a $ sign
  const formatCurrency = (value) => `$${value.toFixed(2)}`;

  return (
    <div className="balance-graph">
      <h2>Account Balance Over Time</h2>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={balanceData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis domain={['auto', 'auto']} tickFormatter={formatCurrency} />
          <Tooltip formatter={(value) => formatCurrency(value)} />
          
          {/* Fill area below the line */}
          <Area 
            type="monotone" 
            dataKey="balance" 
            stroke="#2ecc71" 
            fill="#2ecc71" // Orange fill like the image
            fillOpacity={0.5} 
            activeDot={{ r: 8 }} 
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BalanceGraph;
