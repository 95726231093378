import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import axios from 'axios';  // To fetch the IP
import './Login.css';

const allowedIPs = ['173.66.61.18'];  // List of allowed IPs

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch user's IP using ipify or a similar service
    const fetchIP = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        const userIP = response.data.ip;

        // Check if the user's IP is in the allowed list
        if (allowedIPs.includes(userIP)) {
          setIsAllowed(true);  // Allow access
        } else {
          navigate('/access-denied');  // Block access if IP is not allowed
        }
      } catch (error) {
        console.error('Failed to fetch IP', error);
      }
    };

    fetchIP();
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/admin');
    } catch (error) {
      setError('Failed to log in. Please check your credentials.');
    } finally {
      setLoading(false);
    }
  };

  if (!isAllowed) {
    return null;  // Don't render the login form if the IP is not allowed
  }

  return (
    <div className="login-container">
      <h2>Admin Dashboard</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
      </form>
    </div>
  );
};

export default Login;
