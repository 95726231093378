import React, { createContext, useState, useEffect, useCallback, useMemo } from 'react';
import {
  calculateDaysAlive,
  fetchEntriesAndCalculate
} from '../utils/utils';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  console.log('DataProvider rendered');
  const [data, setData] = useState({
    daysAlive: 0,
    startingCash: 500,
    overallCash: 0,
    totalProfitLossToday: 0,
    totalProfitLossOverall: 0,
    entries: [],
    balanceData: [] // New field for balance graph data
  });
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    console.log('DataContext fetchData called');
    try {
      const calculatedData = await fetchEntriesAndCalculate();
      console.log('Setting data');
      setData({
        daysAlive: calculateDaysAlive(),
        startingCash: 500,
        ...calculatedData
      });
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      console.log('Setting loading to false');
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    console.log('DataContext useEffect triggered');
    fetchData();
    
    // Force loading state to false after 10 seconds
    const timeoutId = setTimeout(() => {
      console.log('Forcing loading state to false after timeout');
      setLoading(false);
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, [fetchData]);

  const contextValue = useMemo(() => ({
    data,
    fetchData,
    loading,
    isLoading: loading
  }), [data, fetchData, loading]);

  return (
    <DataContext.Provider value={contextValue}>
      {children}
    </DataContext.Provider>
  );
};
