// src/components/Spinner.js
import React from 'react';

const Spinner = ({ customClass = '' }) => {
  return (
    <div className={`spinner ${customClass}`}>
      <div className="double-bounce1"></div>
      <div className="double-bounce2"></div>
    </div>
  );
};

export default Spinner;