import { getDocs, collection, query, where, orderBy } from 'firebase/firestore';
import { db } from '../firebase';

const formatAmount = (amount) => {
  return parseFloat(amount).toFixed(2);
};

// Utility function to get today's date in New York time zone
const getNewYorkDate = () => {
  const newYorkTime = new Intl.DateTimeFormat('en-US', {
    timeZone: 'America/New_York',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(new Date());

  // Convert formatted string to 'YYYY-MM-DD' format
  const [month, day, year] = newYorkTime.split('/');
  return `${year}-${month}-${day}`;
};

export const calculateDaysAlive = () => {
  const today = new Date();
  const start = new Date('2024-09-12');
  const diffTime = Math.abs(today - start);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

// Calculate win percentage based on profit/loss
const calculateWinPercentage = (entries) => {
  if (!entries.length) return 0;

  let wins = 0;

  entries.forEach(entry => {
    const amount = parseFloat(entry.amount) || 0;
    if (amount > 0) {
      wins++;
    }
  });

  const totalTrades = entries.length;
  const winPercentage = (wins / totalTrades) * 100;

  return winPercentage.toFixed(2);
};

export const fetchEntriesAndCalculate = async () => {
  try {
    // Get today's date in New York time zone
    const today = getNewYorkDate();

    // Calculate the date one month ago
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    
    const formattedOneMonthAgo = oneMonthAgo.toISOString().split('T')[0];

    const entriesQuery = query(
      collection(db, 'entries'),
      where('date', '>=', formattedOneMonthAgo),
      orderBy('date', 'asc')
    );

    const querySnapshot = await getDocs(entriesQuery);
    const entries = querySnapshot.docs.map(doc => doc.data());

    let overallCash = 500; // startingCash
    let totalProfitLossToday = 0;
    let totalProfitLossOverall = 0;
    let dailyBalances = {};
    let totalCalls = 0;
    let totalPuts = 0;

    // Variables for streak tracking
    let currentStreakType = null;
    let currentStreak = 0;
    let lastTradeResult = null;

    // Sort entries by date and time
    entries.sort((a, b) => {
      const dateCompare = a.date.localeCompare(b.date);
      if (dateCompare !== 0) return dateCompare;
      return a.timeSoldAt.localeCompare(b.timeSoldAt);
    });

    // Iterate through the sorted entries
    entries.forEach((entry) => {
      const amount = parseFloat(entry.amount) || 0;
      overallCash += amount;
      totalProfitLossOverall += amount;

      if (entry.date === today) {
        totalProfitLossToday += amount;
      }

      // Count the number of Calls and Puts
      if (entry.callPut === 'CALL') {
        totalCalls += 1;
      } else if (entry.callPut === 'PUT') {
        totalPuts += 1;
      }

      // Calculate end-of-day balance
      dailyBalances[entry.date] = overallCash;

      // Updated streak calculation logic
      const currentTradeResult = amount > 0 ? 'win' : (amount < 0 ? 'loss' : 'draw');

      if (currentTradeResult !== 'draw') {
        if (lastTradeResult !== currentTradeResult) {
          // Start a new streak
          currentStreakType = currentTradeResult;
          currentStreak = 1;
        } else {
          // Continue the current streak
          currentStreak += 1;
        }
        lastTradeResult = currentTradeResult;
      }
    });

    // Calculate win percentage
    const winPercentage = calculateWinPercentage(entries);

    // Get total number of trades
    const totalTrades = entries.length;

    // Convert dailyBalances object to an array for the graph
    const balanceData = Object.entries(dailyBalances).map(([date, balance]) => ({
      date,
      balance: formatAmount(balance)
    }));

    return {
      overallCash: formatAmount(overallCash),
      totalProfitLossToday: formatAmount(totalProfitLossToday),
      totalProfitLossOverall: formatAmount(totalProfitLossOverall),
      entries: entries,
      balanceData: balanceData,
      winPercentage,
      totalTrades,
      totalCalls,
      totalPuts,
      currentStreakType,
      currentStreak
    };
  } catch (error) {
    console.error("Error fetching and calculating data:", error);
    return {
      overallCash: formatAmount(500),
      totalProfitLossToday: formatAmount(0),
      totalProfitLossOverall: formatAmount(0),
      entries: [],
      balanceData: [],
      winPercentage: 0,
      totalTrades: 0,
      totalCalls: 0,
      totalPuts: 0,
      currentStreakType: null,
      currentStreak: 0
    };
  }
};