import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebase';
import { signOut } from 'firebase/auth';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './AdminSection.css';
import { useNavigate } from 'react-router-dom';

const AdminSection = () => {
  const [entries, setEntries] = useState([]);
  const [newEntry, setNewEntry] = useState({
    date: '',
    stockName: '',
    optionsStrike: '',
    callPut: 'CALL',
    quantity: '',
    priceBoughtAt: '',
    priceSoldAt: '',
    timeSoldAt: '',  // NEW FIELD for Time Sold
    expiration: null,
    amount: '',
  });
  const [editingEntry, setEditingEntry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const navigate = useNavigate();

  const formatDateToYYYYMMDD = (date) => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const fetchEntries = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, 'entries'));
        const fetchedEntries = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  
        // Sort entries by `date` and `timeSoldAt` (descending)
        fetchedEntries.sort((a, b) => {
          const dateComparison = b.date.localeCompare(a.date); // Compare dates
          if (dateComparison !== 0) return dateComparison; // If dates differ, return comparison
          return (b.timeSoldAt || '').localeCompare(a.timeSoldAt || ''); // Sort by timeSoldAt within the same date
        });
  
        setEntries(fetchedEntries);
      } catch (err) {
        setError('Error fetching entries.');
      } finally {
        setLoading(false);
      }
    };
    fetchEntries();
  }, []);
  

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      setError('Error logging out.');
    }
  };

  const handleInputChange = (e, isEditing = false) => {
    const { name, value } = e.target;
    if (isEditing) {
      setEditingEntry({ ...editingEntry, [name]: value });
    } else {
      setNewEntry({ ...newEntry, [name]: value });
    }
  };

  const handleDateChange = (date, isEditing = false) => {
    if (isEditing) {
      setEditingEntry({ ...editingEntry, expiration: date });
    } else {
      setNewEntry({ ...newEntry, expiration: date });
    }
  };

  const handleDropdownChange = (e, isEditing = false) => {
    const { value } = e.target;
    if (isEditing) {
      setEditingEntry({ ...editingEntry, callPut: value });
    } else {
      setNewEntry({ ...newEntry, callPut: value });
    }
  };

  const validateEntry = (entry) => {
    return (
      entry.date &&
      entry.stockName &&
      entry.optionsStrike &&
      entry.callPut &&
      entry.quantity &&
      entry.priceBoughtAt &&
      entry.priceSoldAt &&
      entry.timeSoldAt && // Ensure timeSoldAt is validated
      entry.expiration &&
      entry.amount
    );
  };

  const formatAmount = (amount) => {
    return parseFloat(amount).toFixed(2);
  };

  const addEntry = async () => {
    if (!validateEntry(newEntry)) {
      setError('All fields are required.');
      return;
    }

    setLoading(true);
    try {
      const entryData = {
        ...newEntry,
        amount: parseFloat(formatAmount(newEntry.amount)),
        quantity: parseInt(newEntry.quantity, 10),
        priceBoughtAt: parseFloat(newEntry.priceBoughtAt),
        priceSoldAt: parseFloat(newEntry.priceSoldAt),
        timeSoldAt: newEntry.timeSoldAt,  // Include timeSoldAt in the data
        expiration: newEntry.expiration ? newEntry.expiration.toISOString() : null,
      };
      const docRef = await addDoc(collection(db, 'entries'), entryData);
      setEntries([...entries, { ...entryData, id: docRef.id }]);
      setNewEntry({
        date: '',
        stockName: '',
        optionsStrike: '',
        callPut: 'CALL',
        quantity: '',
        priceBoughtAt: '',
        priceSoldAt: '',
        timeSoldAt: '',  // Reset timeSoldAt field
        expiration: null,
        amount: '',
      });
    } catch (err) {
      setError('Error adding entry.');
    } finally {
      setLoading(false);
    }
  };

  const editEntry = async () => {
    if (!validateEntry(editingEntry)) {
      setError('All fields are required.');
      return;
    }

    setLoading(true);
    try {
      const entryRef = doc(db, 'entries', editingEntry.id);
      const updatedEntry = {
        ...editingEntry,
        amount: parseFloat(formatAmount(editingEntry.amount)),
        quantity: parseInt(editingEntry.quantity, 10),
        priceBoughtAt: parseFloat(editingEntry.priceBoughtAt),
        priceSoldAt: parseFloat(editingEntry.priceSoldAt),
        timeSoldAt: editingEntry.timeSoldAt,  // Include timeSoldAt in update
        expiration: editingEntry.expiration ? new Date(editingEntry.expiration).toISOString() : null,
      };
      await updateDoc(entryRef, updatedEntry);
      const updatedEntries = entries.map((entry) => (entry.id === editingEntry.id ? updatedEntry : entry));
      setEntries(updatedEntries);
      setEditingEntry(null);
    } catch (err) {
      setError('Error updating entry.');
    } finally {
      setLoading(false);
    }
  };

  const deleteEntry = async (id) => {
    setLoading(true);
    try {
      const entryRef = doc(db, 'entries', id);
      await deleteDoc(entryRef);
      setEntries(entries.filter((entry) => entry.id !== id));
    } catch (err) {
      setError('Error deleting entry.');
    } finally {
      setLoading(false);
    }
  };

  const closeEditForm = () => {
    setEditingEntry(null);
  };

  const onDateSelect = (date) => {
    setSelectedDate(date);
  };

  const entriesByDate = entries.reduce((acc, entry) => {
    const entryDate = entry.date;
    if (!acc[entryDate]) acc[entryDate] = [];
    acc[entryDate].push(entry);
    return acc;
  }, {});

  const tileContent = ({ date, view }) => {
    if (view === 'month') {
      const dateStr = formatDateToYYYYMMDD(date);
      if (entriesByDate[dateStr]) {
        return <div className="calendar-entry-dot"></div>;
      }
    }
    return null;
  };

  const renderEntriesForSelectedDate = () => {
    const selectedDateStr = formatDateToYYYYMMDD(selectedDate);
    const entriesForDate = (entriesByDate[selectedDateStr] || []).sort((a, b) => 
      (b.timeSoldAt || '').localeCompare(a.timeSoldAt || '')
    );
    
    return (
      <ul className="admin-section-entry-list">
        {entriesForDate.map((entry) => (
          <li key={entry.id}>
            <div className="entry-details">
              <div><strong>Stock:</strong> {entry.stockName}</div>
              <div><strong>Options Strike:</strong> {entry.optionsStrike}</div>
              <div><strong>Call/Put:</strong> {entry.callPut}</div>
              <div><strong>Quantity:</strong> {entry.quantity}</div>
              <div><strong>Bought At:</strong> ${parseFloat(entry.priceBoughtAt).toFixed(2)}</div>
              <div><strong>Sold At:</strong> ${parseFloat(entry.priceSoldAt).toFixed(2)}</div>
              <div><strong>Time Sold At:</strong> {entry.timeSoldAt}</div> {/* Display timeSoldAt */}
              <div><strong>Expiration:</strong> {entry.expiration ? new Date(entry.expiration).toLocaleDateString() : 'N/A'}</div>
              <div>
                <strong>Amount:</strong> <span className={`amount ${parseFloat(entry.amount) >= 0 ? 'positive' : 'negative'}`}>${parseFloat(entry.amount).toFixed(2)}</span>
              </div>
            </div>
            <div className="entry-actions">
              <button onClick={() => setEditingEntry(entry)} disabled={loading}>
                {loading ? <span className="spinner-small"></span> : 'Edit'}
              </button>
              <button onClick={() => deleteEntry(entry.id)} disabled={loading}>
                {loading ? <span className="spinner-small"></span> : 'Delete'}
              </button>
            </div>
          </li>
        ))}
      </ul>
    );
  };
  

  const renderEntryForm = (entry, isEditing = false) => (
    <div className={`admin-section-form ${isEditing ? 'edit-form' : ''}`}>
      {isEditing ? <h3>Edit Entry</h3> : <h2>Add New Entry</h2>}
      <div className="form-group">
        <label>Date:</label>
        <input
          type="date"
          name="date"
          value={entry.date}
          onChange={(e) => handleInputChange(e, isEditing)}
        />
      </div>
      <div className="form-group">
        <label>Stock Name:</label>
        <input
          type="text"
          name="stockName"
          placeholder="Stock Name"
          value={entry.stockName}
          onChange={(e) => handleInputChange(e, isEditing)}
        />
      </div>
      <div className="form-group">
        <label>Options Strike:</label>
        <input
          type="text"
          name="optionsStrike"
          placeholder="Options Strike"
          value={entry.optionsStrike}
          onChange={(e) => handleInputChange(e, isEditing)}
        />
      </div>
      <div className="form-group">
        <label>Call/Put:</label>
        <select
          name="callPut"
          value={entry.callPut}
          onChange={(e) => handleDropdownChange(e, isEditing)}
        >
          <option value="CALL">CALL</option>
          <option value="PUT">PUT</option>
        </select>
      </div>
      <div className="form-group">
        <label>Quantity:</label>
        <input
          type="number"
          name="quantity"
          placeholder="Quantity"
          value={entry.quantity}
          onChange={(e) => handleInputChange(e, isEditing)}
        />
      </div>
      <div className="form-group">
        <label>Price Bought At:</label>
        <input
          type="number"
          name="priceBoughtAt"
          placeholder="Price Bought At"
          value={entry.priceBoughtAt}
          onChange={(e) => handleInputChange(e, isEditing)}
        />
      </div>
      <div className="form-group">
        <label>Price Sold At:</label>
        <input
          type="number"
          name="priceSoldAt"
          placeholder="Price Sold At"
          value={entry.priceSoldAt}
          onChange={(e) => handleInputChange(e, isEditing)}
        />
      </div>
      <div className="form-group">
        <label>Time Sold At:</label>
        <input
          type="time"  // Use time input for the time sold field
          name="timeSoldAt"
          placeholder="Time Sold At"
          value={entry.timeSoldAt}
          onChange={(e) => handleInputChange(e, isEditing)}
        />
      </div>
      <div className="form-group">
        <label>Expiration Date:</label>
        <DatePicker
          selected={entry.expiration ? new Date(entry.expiration) : null}
          onChange={(date) => handleDateChange(date, isEditing)}
          placeholderText="Expiration Date"
          dateFormat="MM/dd/yyyy"
        />
      </div>
      <div className="form-group">
        <label>Profit/Loss Amount:</label>
        <input
          type="number"
          name="amount"
          placeholder="Profit/Loss Amount"
          value={entry.amount}
          step="0.01"
          onChange={(e) => handleInputChange(e, isEditing)}
        />
      </div>
      <div className="form-actions">
        <button onClick={isEditing ? editEntry : addEntry} disabled={loading}>
          {loading ? <span className="spinner-small"></span> : isEditing ? 'Save Changes' : 'Add Entry'}
        </button>
        {isEditing && (
          <button onClick={closeEditForm} className="cancel-button">
            Cancel
          </button>
        )}
      </div>
    </div>
  );

  return (
    <div className="admin-section-container">
      <div className="admin-section-header">
        <h1>Admin Section</h1>
        <button onClick={handleLogout} className="logout-button">
          Logout
        </button>
      </div>
      {error && <p className="admin-section-error">{error}</p>}

      {renderEntryForm(newEntry)}

      <h2>Entries Calendar</h2>
      <div className="calendar-container">
        <Calendar
          onChange={onDateSelect}
          value={selectedDate}
          tileContent={tileContent}
          className="entries-calendar"
        />
      </div>

      <h2>Entries on {selectedDate.toLocaleDateString()}</h2>
      {renderEntriesForSelectedDate()}

      {editingEntry && (
        <div className="modal-overlay">
          <div className="modal-content">
            {renderEntryForm(editingEntry, true)}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminSection;
